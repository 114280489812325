@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .comute-btn {
   transition: 2s;
  }

  .comute-btn::before {
    content: '';
    position: absolute;
    top: -8px;
    left: -8px;
    border: 1px solid #28598A;
    border-radius: 5px;
    width: 307px;
    height: 60px;
    z-index: 10;
    transition: transform 2s ease-in-out;
  }

    .comute-btn:hover:before {
      content: "123";
      background: #28598A;
      color: #fff;
      transition: transform 2s ease-in-out;
    }



}